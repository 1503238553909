<template>
    <div class="settings__warning bg-danger">
        <div class="warning__wrap">
            <svg class="warning__icon" width="34" height="30" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34 27.6C34 28.38 33.72 28.975 33.1599 29.385C32.5999 29.795 31.9125 30 31.0979 30H2.90207C2.08745 30 1.40012 29.79 0.840072 29.37C0.280024 28.95 0 28.36 0 27.6C0 27 0.183288 26.39 0.549865 25.77L14.6325 1.65C15.3046 0.55 16.0988 0 17.0153 0C17.9317 0 18.7056 0.55 19.3369 1.65L33.4501 25.8C33.8167 26.44 34 27.04 34 27.6ZM19.1231 12.27V7.89H14.8769V12.27C14.8769 12.55 14.8973 12.815 14.938 13.065C14.9787 13.315 15.0347 13.595 15.106 13.905C15.1773 14.215 15.2333 14.48 15.274 14.7L16.0683 19.56H17.8706L18.6954 14.7C18.7361 14.5 18.7972 14.24 18.8787 13.92C18.9602 13.6 19.0213 13.315 19.062 13.065C19.1027 12.815 19.1231 12.55 19.1231 12.27ZM19.1231 23.88C19.1231 23.3 18.9143 22.81 18.4969 22.41C18.0794 22.01 17.5753 21.81 16.9847 21.81C16.4145 21.81 15.9206 22.01 15.5031 22.41C15.0857 22.81 14.8769 23.3 14.8769 23.88C14.8769 24.46 15.0857 24.955 15.5031 25.365C15.9206 25.775 16.4145 25.98 16.9847 25.98C17.5753 25.98 18.0794 25.775 18.4969 25.365C18.9143 24.955 19.1231 24.46 19.1231 23.88Z" fill="white"/>
            </svg>
            <h3 class="h3 warning__title">
                Внимание! Баланс лицевого счёта {{ balance }} рублей. Вы будете отключены через {{Math.floor(count)}}
                {{ daysText }}.
            </h3>
        </div>
        <a href="/billing/balance_replenishment/" class="v-btn v-btn--white warning__btn">Пополнить баланс</a>
    </div>
</template>

<script>
export default {
    name: "Balance",
    data() {
        return {
            daysText: Math.floor(this.count) === 2 ? `дня` : `дней`
        }
    },
    props: {
        balance: {
            required: true
        },
        count: {
            required: true
        }
    },
}
</script>

<style scoped>

</style>
